.ratio {
    > img,
    > picture > img,
    > [data-type='image'] img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .pimcore_editable_image,
    .pimcore_editable_video,
    .pimcore_editable_embed {
        position: absolute !important;

        &,
        &_empty,
        img,
        video,
        iframe {
            width: 100% !important;
            height: 100% !important;
        }
    }
}
