.btn {
    line-height: rem(20);
    z-index: 2;
    position: relative;

    &-cta {
        background-color: $kp-purple;
        border-color: $kp-purple;
        color: $white;

        &:hover {
            background-color: $kp-purple-dark;
            border-color: $kp-purple-dark;
            color: $white;
        }
    }

    &-primary,
    &-secondary,
    &-tag {
        &,
        &:hover {
            color: $kp-purple;
        }
    }

    &-primary,
    &-tag {
        background-color: $kp-purple-lightest;

        &:hover {
            background-color: $kp-purple-light;
        }
    }

    &-primary,
    &-secondary {
        border-color: rgba($kp-purple, 0.26);

        &:hover {
            border-color: rgba($kp-purple, 0.26);
        }
    }

    &-secondary {
        background-color: $white;

        &:hover {
            background-color: $kp-purple-lightest;
        }
    }

    &-tag {
        padding-top: rem(7);
        padding-bottom: rem(7);
        border-radius: $border-radius-pill;
        font-family: $ff-saira;
        border-color: $kp-purple-lightest;
        font-size: rem(18);
        letter-spacing: 0.01em;
        line-height: 1.5rem;

        &:hover {
            border-color: $kp-purple-light;
        }
    }

    &-link {
        @include extend('px-0 py-1 my-n1 link');

        font-size: rem(14);
        line-height: rem(20);

        &--reversed {
            @include extend('link--reversed');
        }
    }
}
