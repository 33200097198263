.intro {
    display: grid;
    grid-template-areas:
        'picture'
        'headline'
        'content';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: $spacer * 2;

    @include media-breakpoint-up(md) {
        grid-template-areas:
            'headline picture'
            'content picture';
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        column-gap: $spacer * 4;
        row-gap: 0;
    }

    &__headline,
    &__icon {
        grid-area: headline;

        @include media-breakpoint-up(md) {
            align-self: center;
        }
    }

    &__picture {
        align-self: center;
        grid-area: picture;
        margin-top: ($spacer * $backdrop-blue-padding) * -1;
        margin-right: calc(var(--container-padding-x) * -1);
        margin-left: calc(var(--container-padding-x) * -1);

        @include media-breakpoint-up(md) {
            margin: 0;

            .ratio {
                overflow: hidden;
                border-radius: $border-radius-lg;
            }
        }
    }

    &__headline {
        z-index: 1;
    }

    &__icon {
        opacity: 0.2;
        position: relative;

        svg {
            position: absolute;
            top: 0;
            left: $spacer * -0.5;

            @include media-breakpoint-up(md) {
                left: $spacer * -2;
                position: relative;
            }

            @include media-breakpoint-up(xl) {
                left: $spacer * -11;
            }
        }
    }

    &__content {
        grid-area: content;
    }
}
