.footer {
    @include extend('bg-kp-offblue text-light py-4 text-center text-xl-start overflow-hidden');

    a {
        @include extend('link-light');
    }

    li > a {
        display: block;
    }

    ul {
        @include extend('list-unstyled');
    }

    .headline {
        text-transform: uppercase;
        margin-bottom: 0;
        font-size: inherit;
        line-height: inherit;
        font-family: $ff-roboto;
        font-weight: 800;
    }

    &__nav {
        line-height: 1.6875;
    }

    &__copyright {
        font-family: $ff-saira;
        letter-spacing: rem(0.5);
        font-size: rem(20);
    }
}
