.podcasts-teaser {
    @include media-breakpoint-up(lg) {
        margin-bottom: rem(36);
    }

    &__title {
        @include extend('h6 mb-2');
    }
    &__picture {
        @include extend('ratio');

        margin-top: rem(-120);
        transform: translateY(rem(60));
        aspect-ratio: 1;

        &:before {
            content: '';
            display: block;
        }
    }
}
