.blog-slider {
    &__tumbnails {
        position: relative;
        height: 100%;
        width: 100%;

        .swiper {
            @include extend('px-2 mx-n2 py-2');

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .swiper-slide {
            @include extend('py-1_5 px-2_5');

            cursor: pointer;

            &:not(:first-of-type) {
                border-top: solid rem(1) $gray-400;
            }
        }

        .swiper-slide-thumb-active {
            border-radius: $border-radius-lg;
            box-shadow: 0 rem(2) rem(20) 0 rgba($black, 0.1), 0 rem(1) rem(3) 0 rgba($black, 0.15);
            background-color: $white;

            &,
            + .swiper-slide {
                border-color: transparent;
            }
        }
    }

    &__main {
        .swiper {
            border-radius: $border-radius-lg;
        }
        .swiper-pagination {
            @include extend('d-lg-none position-relative mt-4');
        }
    }
}
