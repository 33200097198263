.fullwidth-teaser {
    @include extend('py-3 px-2 py-md-4 px-md-3');

    min-height: rem(200);
    position: relative;
    background-color: $white;
    box-shadow: 0 rem(2) rem(25) 0 rgba($black, 0.15);
    border-radius: $border-radius-lg;

    &__content {
        @include extend('vstack gap-2 align-items-center text-center');

        position: relative;
        z-index: 1;
    }

    svg {
        position: absolute;
        top: $spacer * 3;
        left: $spacer * 2;
        opacity: 0.2;

        @include media-breakpoint-up(md) {
            top: 50%;
            left: $spacer * 5;
            transform: translateY(-50%);
        }
    }
}
