.modal {
    &-dialog {
        margin-top: 10vh;
    }

    &--eventbrite {
        [data-js-eventbrite-embed] {
            height: calc(80vh - #{rem(-100)}) !important;
            max-height: rem(600);
        }
    }
}
