.blog-slide {
    @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        border-radius: $border-radius-lg;
        overflow: hidden;
    }

    &__picture,
    &__content {
        @include media-breakpoint-up(lg) {
            grid-column: 1;
            grid-row: 1;
        }
    }

    &__picture {
        border-radius: $border-radius-lg;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            border-radius: 0;
        }

        .ratio {
            min-height: 100%;
        }
    }

    &__content {
        z-index: 1;

        @include media-breakpoint-up(lg) {
            background-image: linear-gradient(to bottom, rgba($black, 0) 50%, rgba($black, 0.5));
        }

        &__inner {
            @include extend('vstack align-self-end gap-1_5 pt-4 px-lg-3 py-lg-2_5 last-child-mb-0');
        }

        .headline {
            @include extend('h2 mb-0');

            @include media-breakpoint-down(lg) {
                @include h4-size;
            }
        }

        p {
            @include extend('h6');

            @include media-breakpoint-down(lg) {
                font-family: $ff-roboto;
                font-size: inherit;
                line-height: inherit;
                font-weight: lighter;
            }
        }

        a {
            color: $body-color;
            height: 100%;
            display: flex;
            justify-content: flex-end;

            @include media-breakpoint-up(lg) {
                color: $white;
            }

            &:hover {
                text-decoration: none;

                .headline {
                    text-decoration: underline;
                }
            }
        }
    }
}
