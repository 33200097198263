.event-info {
    @include extend('vstack gap-3');

    .headline {
        @include extend('h5 mb-0');

        .event-card-preview & {
            @include extend('h6');
        }
    }

    &__icon {
        @include extend('text-primary');

        margin-top: rem(-2);
    }

    .advertorial-marker {
        margin-left: auto;
    }
}
