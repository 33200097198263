@mixin font-face($family, $variants) {
    @each $variant in $variants {
        $weight: map-get($variant, 'weight');
        $url: map-get($variant, 'url');
        @font-face {
            font-family: $family;
            font-style: normal;
            font-weight: $weight;
            src: local(''), url($url) format('woff2');
        }
    }
}

@include font-face(
    'Roboto',
    (
        ('weight': 300, 'url': '../fonts/roboto-v30-latin-300.woff2'),
        ('weight': 400, 'url': '../fonts/roboto-v30-latin-regular.woff2'),
        ('weight': 700, 'url': '../fonts/roboto-v30-latin-700.woff2')
    )
);

@include font-face(
    'Saira Condensed',
    (
        ('weight': 400, 'url': '../fonts/saira-condensed-v11-latin-regular.woff2'),
        ('weight': 600, 'url': '../fonts/saira-condensed-v11-latin-600.woff2')
    )
);
