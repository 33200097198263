.blogpost-infobox {
    @include extend('p-3 p-md-6');

    border: solid $spacer transparent;

    .rte-content,
    .pimcore_wysiwyg {
        > *:first-child {
            margin-top: 0 !important;
        }
        > *:last-child {
            margin-bottom: 0 !important;
        }
    }
}
