.breadcrumbs {
    border-top: solid rem(1) $gray-200;
    border-bottom: solid rem(1) $gray-200;
    margin-top: rem(-1);

    ol {
        @include extend('list-unstyled d-flex gap-0_5 py-2 mb-0');
    }

    a,
    &__active {
        @include extend('btn btn-link');

        color: $gray-800;
    }

    &__active {
        pointer-events: none;

        &:after {
            display: none !important;
        }
    }
}
