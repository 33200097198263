.podcast-tile {
    @include extend('vstack gap-1 position-relative');

    font-size: rem(14);

    &__meta {
        @include extend('hstack gap-1');

        color: $primary;
        font-weight: bold;
    }

    &__title {
        @include extend('h6 stretched-link');

        &,
        &:hover {
            color: inherit;
        }
    }

    &__duration {
        @include extend('hstack gap-1 mt-auto');

        color: $gray-800;

        svg {
            color: $primary;
        }
    }
}
