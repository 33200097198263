body {
    padding-top: var(--header-height);
}

.header {
    box-shadow: 0 rem(1) rem(2) 0 rgba($kp-purple, 0.2);
    background-color: $white;
    top: 0;
    z-index: 10;
    width: 100%;
    position: fixed;

    &:before {
        content: '';
        width: 100%;
        height: rem(8);
        background: linear-gradient(
            90deg,
            $kp-pink 0%,
            $kp-pink 25%,
            $kp-purple 25%,
            $kp-purple 50%,
            $kp-blue 50%,
            $kp-blue 75%,
            $kp-yellow 75%,
            $kp-yellow 100%
        );

        @include media-breakpoint-up(xxl) {
            display: block;
        }
    }

    &__button {
        @include extend('px-1 py-0_5 navbar-toggler link-primary d-block');
    }

    &__search {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        place-items: end;
        align-items: center;
        width: 100%;
        position: relative;
        z-index: 2;

        @include media-breakpoint-up(xxl) {
            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
            }

            &::before {
                top: rem(-4);
                right: rem(-4);
                bottom: rem(-4);
                left: rem(-4);
                z-index: 3;
                cursor: pointer;
            }
        }

        input[type='search'] {
            padding-right: rem(32);

            &::-webkit-search-cancel-button {
                display: none;
            }

            @include media-breakpoint-up(xxl) {
                position: absolute;
                top: rem(-2);
                right: 0;
                bottom: 0;
                opacity: 0;
                transition: width 0.2s, opacity 0.2s;
            }
        }

        &--search-open {
            @include media-breakpoint-up(xxl) {
                &::before {
                    display: none;
                }

                input[type='search'] {
                    @include media-breakpoint-up(xxl) {
                        width: rem(280);
                        opacity: 1;
                    }
                }
            }
        }

        input,
        button {
            grid-row: 1;
            grid-column: 1;
        }

        button {
            color: $primary;
        }
    }

    .navbar-brand {
        margin-right: 0;

        svg {
            width: rem(280);
        }
    }

    .navbar {
        @include extend('py-1_5 py-xxl-2');

        &-nav {
            @include extend('gap-xxl-2');

            filter: blur(0);
            opacity: 1;
            transition: opacity 0.2s, filter 0.2s;
            will-change: opacity, filter;
            font-family: $ff-saira;
            text-transform: uppercase;
            font-size: rem(20);

            @include media-breakpoint-up(xxl) {
                font-size: rem(18);
                font-weight: bold;
            }

            .nav-item {
                .nav-link {
                    @include extend('py-0_5');

                    color: $primary;

                    &:hover {
                        color: darken($primary, 15%);
                    }

                    &.active {
                        position: relative;

                        &:hover {
                            color: $primary;
                        }

                        @include media-breakpoint-up(xxl) {
                            &::before {
                                content: '';
                                display: block;
                                position: absolute;
                                right: rem(8);
                                bottom: rem(8);
                                left: rem(8);
                                height: rem(2);
                                background-color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }

    &--search-open {
        .navbar-nav {
            filter: blur(0.25rem);
            opacity: 0.4;
            pointer-events: none;
        }
    }
}
