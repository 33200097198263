$decorationOffset: 0.19;

@mixin h1-size {
    font-size: rem(64);
    line-height: 1.09;
}
@mixin h2-size {
    font-size: rem(48);
    line-height: 1.13;
}
@mixin h3-size {
    font-size: rem(36);
    line-height: 1.11;
}
@mixin h4-size {
    font-size: rem(28);
    line-height: 1.14;
}
@mixin h5-size {
    font-size: rem(24);
    line-height: 1.17;
}
@mixin h6-size {
    font-size: rem(18);
    line-height: 1.22;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $ff-saira;
    font-weight: bold;
    font-stretch: condensed;
    word-break: break-word;

    .rte-content & {
        margin-top: 1.2em;
        margin-bottom: 0.4em;
    }
}

h1 {
    @include h1-size;

    &.decorated {
        &:before {
            height: #{(1.09 - $decorationOffset) + 'em'};
        }
    }
}
h2 {
    @include h2-size;

    &.decorated {
        &:before {
            height: #{(1.13 - $decorationOffset) + 'em'};
        }
    }
}
h3 {
    @include h3-size;

    &.decorated {
        &:before {
            height: #{(1.11 - $decorationOffset) + 'em'};
        }
    }
}
h4 {
    @include h4-size;

    &.decorated {
        &:before {
            height: #{(1.14 - $decorationOffset) + 'em'};
        }
    }
}
h5 {
    @include h5-size;

    &.decorated {
        &:before {
            height: #{(1.17 - $decorationOffset) + 'em'};
        }
    }
}
h6 {
    @include h6-size;

    &.decorated {
        &:before {
            height: #{(1.22 - $decorationOffset) + 'em'};
        }
    }
}

.decorated {
    display: flex;
    gap: rem(8);

    &:before {
        content: '';
        display: block;
        width: rem(24);
        background-position: center bottom;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 156 156' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='%234885ED' cx='77.5' cy='117' r='39'/%3E%3Ccircle fill='%23F4C20D' cx='39' cy='78' r='39'/%3E%3Ccircle fill='%23E2017A' cx='77.5' cy='39' r='39'/%3E%3Ccircle fill='%23654294' cx='117' cy='78' r='39'/%3E%3Cpath d='M78 156a39 39 0 1 0 0-78' fill='%234885ED'/%3E%3C/svg%3E");
    }
}
