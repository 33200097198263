.blogpost-gallery {
    .swiper {
        .swiper-slide {
            @include media-breakpoint-down(xl) {
                width: rem(400);
                flex-basis: rem(400);
            }

            @include media-breakpoint-down(lg) {
                width: rem(330);
                flex-basis: rem(330);
            }

            @include media-breakpoint-down(md) {
                width: rem(220);
                flex-basis: rem(220);
            }
        }
    }
}
