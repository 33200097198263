figure {
    margin: 0;

    figcaption {
        &:last-child:not(:first-child) {
            border-top: 0;
        }
        &:first-child:not(:last-child) {
            border-bottom: 0;
        }
    }
}
