.rte-content {
    > ul,
    > ol {
        @include extend('my-3');
    }

    ul,
    ol {
        line-height: 1.375;
        @include extend('ps-0');

        display: flex;
        flex-direction: column;
        gap: 1.5em;
        list-style: none;

        > li {
            @include extend('ps-4');

            position: relative;

            &:before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                font-weight: 800;
            }
        }

        ul,
        ol {
            padding-top: 0.5em;
            gap: 0.5em;
        }
    }

    ul {
        > li {
            &:before {
                content: '•';
                transform: translateX(0.15em);
            }
        }
    }

    ol {
        counter-reset: list-number;

        > li {
            counter-increment: list-number;

            &:before {
                content: counter(list-number) '.';
            }
        }
    }
}
