.topic-card {
    @include extend('link-light');

    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    border-radius: $border-radius;

    @include media-breakpoint-up(lg) {
        border-radius: $border-radius-lg;
    }

    .ratio {
        min-height: 100%;
    }

    &__picture,
    &__content {
        grid-column: 1;
        grid-row: 1;
    }

    &__picture {
        width: 100%;
        position: relative;
    }

    &__content {
        @include extend('py-2 px-1_5 p-lg-2');

        display: flex;
        place-items: flex-end;
        z-index: 1;
        background: linear-gradient(to bottom, rgba($black, 0) 70%, rgba($black, 0.5));
    }

    &__border {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: rem(4);
        z-index: 1;
        pointer-events: none;

        @include media-breakpoint-up(lg) {
            height: rem(6);
        }
    }
}
