.video-card {
    color: inherit;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &:hover {
        color: inherit;
        text-decoration: none;

        .headline {
            text-decoration: underline;
        }
    }

    &__picture {
        @include extend('play-button');

        width: 100%;
    }

    &__content {
        @include extend('pt-1_5 vstack gap-1_5');
    }
}
