:root {
    --ad-skyscraper-width: #{0 + 'rem'}; // has to be written this way
    --container-padding-x: 1rem;

    @include media-breakpoint-up(md) {
        --container-padding-x: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
        --container-padding-x: 6rem;
    }
}
