.swiper {
    width: 100%;

    .swiper-slide {
        height: auto;
        display: flex;
    }

    &.swiper-vertically {
        .swiper-wrapper {
            flex-direction: column;
        }
    }
}

.swiper-wrapper.row {
    width: calc(100% + #{$grid-gutter-width * 1});
    flex-wrap: nowrap;
}

@each $breakpoint, $value in $grid-breakpoints {
    .swiper--disable-up-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            overflow: visible !important;

            .swiper-wrapper {
                transform: none !important;
                flex-wrap: wrap !important;
            }
        }
    }
}
