.blogpost-info {
    @include extend('vstack gap-0_5');

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: #{'-webkit-box'}; // has to be written like that to prevent prettier from formatting
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        #{'box-orient'}: vertical;
    }
}
