.eyebrowline {
    font-family: $ff-saira;
    text-transform: uppercase;
    color: $kp-purple;
    line-height: 1.5;
    font-size: rem(16);
    font-weight: 600;

    &--bg-blue {
        @include extend('px-1_5');

        color: $white;
        background-color: $kp-blue;
        display: inline-block;
    }

    &--advertorial {
        display: grid;
        gap: rem(8) rem(24);
        grid-template-rows: auto rem(24) auto;
        grid-template-columns: auto 1fr auto;
        align-items: center;

        @include media-breakpoint-up(sm) {
            grid-template-rows: auto;
            grid-template-columns: #{rem(160)} 1fr #{rem(160)};
        }

        .advertorial-branding {
            grid-row: 1;
            grid-column: 1;

            @include media-breakpoint-up(sm) {
                grid-row: 1;
                grid-column: 1;
            }

            img {
                width: 100%;
                display: block;
                max-height: rem(84);
                object-fit: contain;
                object-position: left center;
            }
        }
        .eyebrowline__text {
            grid-row: 3;
            grid-column: 1 / 4;

            @include media-breakpoint-up(sm) {
                grid-row: 1;
                grid-column: 2;
            }
        }
        .advertorial-marker {
            grid-row: 1;
            grid-column: 3;
            color: $body-color;
            font-weight: normal;
            text-transform: initial;
            text-align: center;
            place-content: start;

            @include media-breakpoint-up(sm) {
                grid-row: 1;
                grid-column: 3;
                text-align: right;
            }
        }
    }
}
