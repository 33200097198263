.stage {
    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        place-items: center;
    }

    &__picture {
        @include extend('ratio ratio-cinemascope');

        min-height: 100%;

        @include media-breakpoint-up(md) {
            grid-column: 1 / 3;
            grid-row: 1;
        }
    }

    &__content {
        @include extend('py-md-5');

        position: relative;
        z-index: 1;
        background-color: $primary;

        @include media-breakpoint-up(md) {
            grid-column: 1;
            grid-row: 1;
            background-color: transparent;
        }

        &__card {
            @include extend('py-3 px-md-3 ms-xl-n3');
            @include extend('vstack gap-1 gap-md-2');

            color: $white;

            @include media-breakpoint-up(md) {
                border-radius: $border-radius-lg;
                background-color: rgba($primary, 0.8);
                backdrop-filter: blur(#{rem(8)});
            }
        }
    }
}
