.ad {
    color: $gray-800;
    font-size: rem(14);
    font-family: $ff-saira;
    display: flex;
    justify-content: center;
    z-index: 1;
    position: relative;

    a {
        display: block;
    }

    &-skyscraper {
        @include extend('container-standard');

        height: 0;
        justify-content: flex-end;

        @include media-breakpoint-down(xl) {
            display: none;
        }

        &__inner {
            @include extend('pt-3');
        }
    }
}

body:not(.skip-adbanner-fix) {
    .ad {
        div[style*='left:'] {
            left: initial !important;
        }
    }
}
