.event-card {
    width: 100%;
    box-shadow: 0 rem(2) rem(24) 0 rgba($black, 0.1);
    background-color: $white;
    border-radius: $border-radius-lg;
    overflow: hidden;

    &__content {
        @include extend('p-2 pb-4');
    }

    &__button {
        @include extend('btn btn-cta btn-block');

        padding-top: rem(9);
        padding-bottom: rem(9);
        border-radius: 0;
    }

    &--advertorial {
        background-color: $kp-advertorial;
    }
}
