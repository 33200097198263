.blogpost-text {
    &__content {
        p,
        strong {
            @include fz-large;
        }

        a {
            &:not([class]) {
                @include extend('link');
            }
        }
    }
}
