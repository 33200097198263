@mixin fz-large {
    font-size: rem(18);
    line-height: rem(28);
}

p {
    font-size: 16px;
    line-height: 1.5;

    &.small {
        font-size: 12px;
        line-height: 1.5;
    }
    &.boldsmall {
        font-size: 14px;
        line-height: 1.43;
    }

    &.large {
        @include media-breakpoint-up(md) {
            @include fz-large;
        }
    }

    &.lead {
        @include fz-large;

        font-family: $ff-saira;
    }
}
