.blogpost-intro {
    &__meta {
        @include extend('py-2');

        border-top: solid rem(1) $gray-400;
        border-bottom: solid rem(1) $gray-400;

        &__info {
            @include extend('vstack gap-0_5');

            font-size: rem(14);
            line-height: rem(20);
            color: $gray-900;
        }
    }
}
