.link {
    display: inline-flex;
    align-items: center;
    gap: rem(4);
    hyphens: auto;

    &:after {
        content: '';
        display: block;
        border: 0;
        background: transparent;
        box-sizing: border-box;
        width: rem(4);
        height: rem(4);
        border-top: solid rem(1) currentcolor;
        border-right: solid rem(1) currentcolor;
        margin-top: 0.1em;
        transform: rotate(45deg);
    }

    &--reversed {
        flex-direction: row-reverse;

        &:after {
            transform: rotate(225deg);
        }
    }
}

.rte-content {
    .link {
        display: inline;

        &:after {
            display: inline-block;
            margin: 0.1em 0.2em 0 0.1em;
            transform: translateY(-0.2em) rotate(45deg);
        }
    }
}
