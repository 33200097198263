#CybotCookiebotDialog:not(.x) {
    #CybotCookiebotDialog {
        font-family: inherit;

        h2 {
            @include extend('h4 mb-2');
        }
    }

    #CybotCookiebotDialogNav {
        .CybotCookiebotDialogNavItemLink {
            &:hover {
                color: $primary;
            }

            &.CybotCookiebotDialogActive {
                border-color: currentcolor;
                color: $primary;
            }
        }
    }

    a:not(.x) {
        font-weight: initial;
        color: $primary !important;
    }

    #CybotCookiebotDialogFooter {
        .CybotCookiebotDialogBodyButton {
            @include extend('btn btn-lg justify-content-center');
        }

        #CybotCookiebotDialogBodyButtonDecline,
        #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
        #CybotCookiebotDialogBodyLevelButtonCustomize {
            @include extend('btn-outline-primary');
        }

        #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
            @include extend('btn-primary');
        }

        .CybotCookiebotDialogHide {
            display: none;
        }
    }

    #CybotCookiebotDialogTabContent input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: $primary;
    }

    #CybotCookiebotDialogTabContent
        form
        input[type='checkbox'][disabled]:checked
        + .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: $gray-200;
    }
}

#CookiebotWidget:not(.x) {
    font-family: inherit;

    h2 {
        @include extend('h4 mb-2');
    }

    a {
        font-weight: initial;
        @extend a;
    }

    #CookiebotWidget-buttons {
        #CookiebotWidget-btn-withdraw,
        #CookiebotWidget-btn-change {
            @include extend('btn btn-lg justify-content-center');
        }

        #CookiebotWidget-btn-withdraw {
            @include extend('btn-outline-primary');
        }

        #CookiebotWidget-btn-change {
            @include extend('btn-primary');
        }
    }

    .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
        fill: $primary;
    }

    .CookiebotWidget-consent-details button {
        color: $primary;
    }
}
