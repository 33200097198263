.blog-card-listing {
    &__row {
        @include media-breakpoint-down(xl) {
            > *:nth-of-type(4) ~ * {
                order: 5;
            }
        }
        @include media-breakpoint-up(xl) {
            > *:nth-of-type(3) ~ * {
                order: 5;
            }
        }

        &__intermission {
            order: 4;
        }
    }
}
