.blogpost {
    &--advertorial {
        background-color: $kp-advertorial;
    }

    .rte-content {
        h1 {
            @include h2-size;
        }
        h2 {
            @include h3-size;
        }
        h3 {
            @include h4-size;
        }
        h4 {
            @include h5-size;
        }
        h5 {
            @include h6-size;
        }
    }
}
