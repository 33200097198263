.mb-el {
    @include extend('mb-4 mb-md-5 mb-lg-7');
}
.my-el {
    @include extend('my-4 my-md-5 my-lg-7');
}

.mt-el-sm {
    @include extend('mt-4');
}
.mb-el-sm {
    @include extend('mb-4');
}
.my-el-sm {
    @include extend('my-4');
}

[class*='gap-y-'] {
    gap: var(--bs-gap-y, #{$spacer * 4}) 0;
}

$backdrop-blue-padding: 5;
.backdrop-blue {
    @include extend('py-' + $backdrop-blue-padding);

    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -50vw;
        bottom: 0;
        left: -50vw;
        background-color: rgba($kp-blue, 0.03);
        border: solid rem(1) rgba($kp-purple, 0.2);
        border-right: 0;
        border-left: 0;
        pointer-events: none;
        z-index: -1;
    }
}

.container-standard {
    @include extend('container-xxl');

    & & {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

.pimcore_area_content .container-standard {
    @include media-breakpoint-up(xl) {
        padding-right: calc(
            var(--bs-gutter-x, var(--container-padding-x)) + var(--ad-skyscraper-width)
        );
    }
}

.last-child-mb-0 {
    > *:last-child {
        margin-bottom: 0 !important;
    }
}

.play-button {
    position: relative;

    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        z-index: 1;
    }

    &:before {
        border-radius: 100%;
        height: rem(56);
        width: rem(56);
        background-color: rgba($black, 0.2);
    }

    &:after {
        $w: 18;

        border: 0;
        background: transparent;
        box-sizing: border-box;
        width: 0;
        height: rem($w);
        border-color: transparent transparent transparent $white;
        transition: 100ms all ease;
        cursor: pointer;
        border-style: solid;
        margin-left: rem(2);
        border-width: rem($w * 0.65) 0 rem($w * 0.65) rem($w);
    }
}
