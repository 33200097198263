.dropdown {
    &-toggle {
        &::after {
            border-top: 0;
            border-right: 0.15em solid;
            border-bottom: 0.15em solid;
            border-left: 0;
            height: 0.44em;
            width: 0.44em;
            transform: translateY(0.025em) rotate(45deg);
            margin-left: 0.1em;
            margin-right: -0.75em;
        }

        &.show {
            &::after {
                transform: translateY(0.2em) rotate(225deg);
            }
        }
    }
}

.dropdown-menu {
    @include extend('px-0 py-2_5 ms-n3');

    font-family: $ff-saira;
    text-transform: uppercase;
    border-radius: rem(4);
    background-color: $white;
    box-shadow: 0 rem(2) rem(20) 0 rgba($black, 0.15);
    border-width: 0;
    font-size: rem(20);
    margin-top: rem(16) !important;
    font-weight: normal;

    &-end {
        right: rem(-8);
    }

    .dropdown-item {
        @include extend('d-flex align-items-center gap-2');

        svg {
            @include extend('ms-n2 my-n1');
        }
    }

    a {
        @include extend('px-4');

        text-transform: none;
        color: $primary;
        border-bottom: rem(1) solid $white;

        &:hover,
        &.active {
            color: $primary;
            background-color: lighten($primary, 55%);
        }
    }
}

.offcanvas-body {
    @include media-breakpoint-down(xxl) {
        .dropdown-menu {
            box-shadow: none;
            padding: $spacer * 0.5 0 $spacer 0 !important;
            margin: 0 0 0 $spacer * 2 !important;

            a {
                font-size: inherit;
                padding-left: $spacer !important;
                padding-right: $spacer !important;
            }
        }
        .dropdown-toggle {
            &::after {
                border-right: 0.1em solid;
                border-bottom: 0.1em solid;
            }
        }
    }
}
