.accordion {
    &-item {
        border-right: 0;
        border-left: 0;
    }

    &-button {
        @include extend('h6 mb-0 gap-1_5');

        box-shadow: none !important;
        font-family: $ff-roboto;

        &__title {
            flex-grow: 1;
            order: 1;
        }

        &:before,
        &:after {
            order: 2;
        }

        &:before {
            display: inline-block;
            font-weight: 300;
            font-size: rem(16);
            line-height: rem(21);
            color: $primary;
        }

        &:after {
            border-top: rem(2) solid $primary;
            border-right: rem(2) solid $primary;
            width: rem(8);
            height: rem(8);
        }

        &:not(.collapsed) {
            &:before {
                content: attr(data-text-hide);
            }

            &:after {
                transform: translateY(#{rem(3)}) rotate(315deg);
            }
        }

        &.collapsed {
            &:before {
                content: attr(data-text-show);
            }
            &:after {
                transform: translateY(#{rem(-1)}) rotate(135deg);
            }
        }
    }
}
