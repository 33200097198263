// Function for converting a px based font-size to rem.
@function rem($size) {
    $remSize: $size / 16;
    // Default font size on html element is 100%, equivalent to 16px;
    @return #{$remSize}rem;
}

@function ratio($w, $h) {
    @return $h / $w * 100 + '%';
}
