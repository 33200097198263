.search-result {
    display: grid;
    grid-template-columns: 3fr 5fr;
    gap: $spacer * 2;
    place-items: flex-start;
    position: relative;

    &__picture {
        grid-column: 1;
        @include extend('ratio ratio-16x10');

        background-color: $gray-100;

        @include media-breakpoint-down(lg) {
            --bs-aspect-ratio: 100%;
        }

        svg {
            position: absolute;
            height: 100%;
            width: 40%;
            max-width: rem(100);
            top: 0%;
            left: 50%;
            transform: translateX(-50%);
            color: $gray-400;
        }
    }

    &__content {
        @include extend('pb-2');

        grid-column: 2;
        border-bottom: rem(1) solid $gray-400;
        position: relative;
    }

    &__picture:hover ~ .search-result__content,
    &__content {
        .stretched-link {
            &:hover {
                ~ div {
                    .headline {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .event-info {
        @include extend('gap-1');
    }
}
