/**
 * This enhances the native html5 lazyload by adding
 * state representing classes to our picture.
 * This is coined to pimcores automatic image deferring.
 * lazy-image.js needed for this to work properly.
 * @author Thomas Franz <thomas.franz@krankikom.de>
 * @version 1.0.0
 */

.lazy-image {
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity 0.3s, background-color 0.3s;
        opacity: 0;
        pointer-events: none;
        background-color: $gray-200;
        background-image: linear-gradient(90deg, transparent 0%, $gray-100 33%, transparent 50%);
        background-repeat: no-repeat;
        background-size: 200% 100%;
        background-position-x: 200%;
        animation: 1.5s shine linear infinite;
    }

    &--waiting,
    &--error {
        &:after {
            opacity: 1;
        }
    }

    &--error {
        &:after {
            animation: none;
            background-color: lighten(crimson, 48%);
            background-position: center;
            background-size: 0% 0%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fa415b' stroke-width='2'%3E%3Ccircle cx='12' cy='12' r='11'/%3E%3Cpath d='m7.3 16.7 9.4-9.4m0 9.4L7.3 7.3'/%3E%3C/svg%3E");
        }

        img {
            visibility: hidden;
        }

        &-show {
            &:after {
                transition: background-size 0.2s;
                background-size: 15% 15%;
            }
        }
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
