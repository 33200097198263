.blog-card {
    width: 100%;
    position: relative;
    color: inherit;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &--video {
        .blog-card__picture {
            @include extend('play-button');
        }
    }

    &--advertorial {
        .blog-card__content {
            background-color: $kp-advertorial;
        }
    }

    .stretched-link {
        z-index: 2;
    }

    &:hover {
        color: inherit;
        text-decoration: none;

        .headline {
            text-decoration: underline;
        }
    }

    &__picture {
        width: 100%;
    }

    &__content {
        @include extend('py-1_5 px-2 mx-2 mt-n4 d-flex');

        position: relative;
        z-index: 1;
        flex-grow: 1;
        background-color: $white;
        border-radius: $border-radius-lg;
        box-shadow: 0 rem(2) rem(25) 0 rgba($black, 0.15);
    }
}
