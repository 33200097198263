/* ************************************* */
/*         Do-not-change section         */
/* ************************************* */
$enable-rfs: false;
$enable-negative-margins: true;

/* ************************************* */
/*       Project-specific variable       */
/*         overrides from here on        */
/* ************************************* */

$ff-roboto: 'Roboto', sans-serif;
$ff-saira: 'Saira Condensed', sans-serif;
$font-family-sans-serif: $ff-roboto;

$font-weight-normal: 300;

$kp-offblue: #283583;
$kp-pink: #e2047a;
$kp-purple: #664391;
$kp-blue: #537dbe;
$kp-yellow: #f1c00c;
$kp-advertorial: #fcf1e3;

$primary: $kp-purple;

$kp-purple-dark: #290060;
$kp-purple-light: #e0d6e9;
$kp-purple-lightest: #f0ecf4;

$secondary-red: #c22b28;
$secondary-green: #6dd400;

$white: #ffffff;
$gray-100: #f9f9f9;
$gray-200: #e8e8e8;
$gray-400: #cccccc;
$gray-600: #afafaf;
$gray-800: #969696;
$gray-900: #646464;
$black: #000000;

$body-bg: $white;

$grays: (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-400,
    '400': $gray-400,
    '500': $gray-600,
    '600': $gray-600,
    '700': $gray-800,
    '800': $gray-800,
    '900': $gray-900,
);

$custom-theme-colors: (
    'kp-offblue': $kp-offblue,
    'kp-pink': $kp-pink,
    'kp-purple': $kp-purple,
    'kp-blue': $kp-blue,
    'kp-yellow': $kp-yellow,
    'kp-advertorial': $kp-advertorial,
    'secondary-red': $secondary-red,
    'secondary-green': $secondary-green,
    600: $gray-600,
);

$spacer: 0.5rem;
$spacers: (
    '0': 0,
    '0_25': $spacer * 0.25,
    '0_5': $spacer * 0.5,
    '1': $spacer * 1,
    '1_5': $spacer * 1.5,
    '2': $spacer * 2,
    '2_5': $spacer * 2.5,
    '3': $spacer * 3,
    '4': $spacer * 4,
    '5': $spacer * 5,
    '6': $spacer * 6,
    '7': $spacer * 7,
    '8': $spacer * 8,
    '9': $spacer * 9,
    '10': $spacer * 10,
);

$container-padding-x: var(--container-padding-x);

$link-color: $kp-purple;
$link-decoration: none;
$link-hover-color: $kp-purple-dark;
$link-hover-decoration: underline;

$border-radius: 0.25rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: 0.5rem;

$font-size-base: 1rem;
$body-color: $black;

$input-btn-font-size: $font-size-base;
$input-btn-padding-y: 0.5625rem;
$input-btn-padding-x: 0.9375rem;
$input-btn-line-height: null;

$input-btn-padding-y-sm: 0.4375rem;
$input-btn-padding-x-sm: $input-btn-padding-x;
$input-btn-font-size-sm: $input-btn-font-size;

$input-btn-padding-y-lg: 0.9375rem;
$input-btn-padding-x-lg: $input-btn-padding-x;
$input-btn-font-size-lg: $input-btn-font-size;

// $input-btn-padding-y:         .375rem !default;
// $input-btn-padding-x:         .75rem !default;
// $input-btn-font-family:       null !default;
// $input-btn-font-size:         $font-size-base !default;
// $input-btn-line-height:       $line-height-base !default;

// $input-btn-focus-width:         .25rem !default;
// $input-btn-focus-color-opacity: .25 !default;
// $input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
// $input-btn-focus-blur:          0 !default;
// $input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

// $input-btn-padding-y-sm:      .25rem !default;
// $input-btn-padding-x-sm:      .5rem !default;
// $input-btn-font-size-sm:      $font-size-sm !default;

// $input-btn-padding-y-lg:      .5rem !default;
// $input-btn-padding-x-lg:      1rem !default;
// $input-btn-font-size-lg:      $font-size-lg !default;

// $input-btn-border-width:      $border-width !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1280px,
    xxl: 1440px,
);

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y: 1rem;
$accordion-padding-x: 0;
$accordion-border-color: $gray-400;
$accordion-border-radius: 0;
$accordion-inner-border-radius: 0;

$accordion-button-active-bg: $body-bg;
$accordion-button-active-color: $body-color;

$accordion-button-icon: none;
$accordion-button-active-icon: none;

$aspect-ratios: (
    '1x1': 100%,
    '4x3': ratio(4, 3),
    '16x10': ratio(16, 10),
    '16x9': ratio(16, 9),
    '21x9': ratio(21, 9),
    'cinemascope': ratio(2.35, 1),
);
