.listing-navigation {
    @include extend('p-3');

    border: solid rem(1) $gray-200;
    display: grid;
    background-color: $gray-100;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto;

    &__prev,
    &__current,
    &__next {
        grid-row: 1;
    }

    &__prev {
        grid-column: 1;
        place-self: flex-start;
    }

    &__current {
        grid-column: 2;
        font-weight: bold;
    }

    &__next {
        grid-column: 3;
        place-self: flex-end;
    }
}
