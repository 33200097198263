.event-strip {
    @include extend('py-3 px-1_5');

    position: relative;
    border-top: solid rem(1) $gray-400;
    border-bottom: solid rem(1) $gray-400;

    & + & {
        border-top: 0;
    }

    &__link {
        @include extend('stretched-link');
    }

    &--advertorial {
        background-color: $kp-advertorial;
    }
}
