.offcanvas {
    &-header,
    &-body {
        @include media-breakpoint-down(xxl) {
            padding: $spacer * 2;
        }
    }

    &-header {
        svg {
            width: rem(24) !important;
        }
    }

    &-body {
        hr {
            @include extend('d-xxl-none');

            border-color: $gray-400;
            margin: 0;
        }
    }
}
